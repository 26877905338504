import styled from 'styled-components'
import Link from 'next/link'
import { Flex, HelpIcon, Button, ShieldIcon } from '@pancakeswap/uikit'
import { useGetPredictionsStatus } from 'state/predictions/hooks'
import { PredictionStatus } from 'state/types'
import FlexRow from './FlexRow'
import { PricePairLabel, TimerLabel } from './Label'
import PrevNextNav from './PrevNextNav'
import HistoryButton from './HistoryButton'

const SetCol = styled.div`
  flex: none;
  width: auto;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 270px;
  }
`

const HelpButtonWrapper = styled.div`
  order: 1;
  margin: 0 2px 0 8px;

  ${({ theme }) => theme.mediaQueries.sm} {
    order: 2;
    margin: 0 0 0 8px;
  }
`

const TimerLabelWrapper = styled.div`
  order: 3;
  width: 100px;

  ${({ theme }) => theme.mediaQueries.sm} {
    order: 1;
    width: auto;
  }
`

const LeaderboardButtonWrapper = styled.div`
  display: block;

  order: 2;
  margin: 0 8px 0 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    order: 3;
    margin: 0 0 0 8px;
  }
`

const ButtonWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
    margin-left: 8px;
  }
`

const StyledTopMenu = styled(FlexRow)`
  padding-bottom: 31px;

  @media (max-width: 739px) {
    flex-direction: row;
    height: 80px;
    padding: 16px;
    justify-content: space-between;
  }
`

const StyledPrevNextNavWrapper = styled(FlexRow)`
  @media (max-width: 739px) {
    display: none;
  }
`

const Menu = () => {
  const status = useGetPredictionsStatus()

  return (
    <StyledTopMenu alignItems="center" p="16px" width="100%">
      {status === PredictionStatus.LIVE && (
        <SetCol>
          <PricePairLabel />
        </SetCol>
      )}
      {status === PredictionStatus.LIVE && (
        <>
          <StyledPrevNextNavWrapper justifyContent="center">
            <PrevNextNav />
          </StyledPrevNextNavWrapper>
          <SetCol>
            <Flex alignItems="center" justifyContent="flex-end" height="50px">
              <TimerLabelWrapper>
                <TimerLabel interval="5" unit="m" />
              </TimerLabelWrapper>
              <HelpButtonWrapper>
                <Button
                  variant="tertiary"
                  as="a"
                  href="https://docs.opx.finance/o-prediction"
                  target="_blank"
                  rel="noreferrer noopener"
                  width="50px"
                >
                  <HelpIcon width="24px" color="white" />
                </Button>
              </HelpButtonWrapper>
              <LeaderboardButtonWrapper>
                <Button
                  as="a"
                  href="prediction/leaderboard"
                  variant="tertiary"
                  width="50px"
                >
                  <ShieldIcon color="white" />
                </Button>
              </LeaderboardButtonWrapper>
              {/* <LeaderboardButtonWrapper>
                <Link href="/prediction/leaderboard" passHref>
                  <Button as="a" variant="tertiary" width="50px">
                    <ShieldIcon color="white" />
                  </Button>
                </Link>
              </LeaderboardButtonWrapper> */}
              <ButtonWrapper style={{ order: 4 }}>
                <HistoryButton />
              </ButtonWrapper>
            </Flex>
          </SetCol>
        </>
      )}
    </StyledTopMenu>
  )
}

export default Menu
