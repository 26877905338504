import { useState } from 'react'
import {
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  IconButton,
  InfoIcon,
  PlayCircleOutlineIcon,
  Text,
  useTooltip,
  WaitIcon,
} from '@pancakeswap/uikit'
import { useWeb3React } from '@pancakeswap/wagmi'
import styled from 'styled-components'
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch'
import { Bet, PredictionStatus } from 'state/types'
import { REWARD_RATE } from 'state/predictions/config'
import { useGetCurrentEpoch, useGetIsClaimable, useGetPredictionsStatus } from 'state/predictions/hooks'
import { fetchLedgerData, markAsCollected } from 'state/predictions'
import { getRoundResult, Result } from 'state/predictions/helpers'
import { useTranslation } from '@pancakeswap/localization'
import { formatBnb, getNetPayout } from './helpers'
import CollectWinningsButton from '../CollectWinningsButton'
import ReclaimPositionButton from '../ReclaimPositionButton'
import BetDetails from './BetDetails'
import { useConfig } from '../../context/ConfigProvider'
import usePollOraclePrice from 'views/Predictions/hooks/usePollOraclePrice'
import useTokenInfo from 'hooks/useTokenInfo'
import useSWR from 'swr'
import { arrayURLFetcher } from 'utils/legacy'

interface BetProps {
  bet: Bet
}

const StyledBet = styled(Flex).attrs({ alignItems: 'center', p: '16px' })`
  background-color: ${({ theme }) => theme.card.background};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
`

const YourResult = styled(Box)`
  flex: 1;
`

const CustomIconButton = styled(IconButton)`
  background: none;
`

const HistoricalBet: React.FC<React.PropsWithChildren<BetProps>> = ({ bet }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { amount, round } = bet
  const { t } = useTranslation()
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    <>
      <Text bold mb="4px">
        {t('Neither position wins this round.')}
      </Text>
      <Text>
        {t(
          'The locked and closed prices are identical (within 8 decimal places), so ALL funds invested in both positions go to the Treasury.',
        )}
      </Text>
    </>,
    { placement: 'top' },
  )

  const currentEpoch = useGetCurrentEpoch()
  const status = useGetPredictionsStatus()
  const canClaim = useGetIsClaimable(bet.round.epoch)
  const dispatch = useLocalDispatch()
  const { account } = useWeb3React()
  const { displayedDecimals } = useConfig()
  const { price } = usePollOraclePrice()
  // const tokenInfo = useTokenInfo();
  // const { data: tokenInfo } = useSWR("https://api.opx.finance/opx/token-info?token=OPX&chainCode=Optimism", {
  //   fetcher: arrayURLFetcher,
  // });
  const toggleOpen = () => setIsOpen(!isOpen)

  const getRoundColor = (result) => {
    switch (result) {
      case Result.WIN:
        return 'success'
      case Result.LOSE:
        return 'failure'
      case Result.CANCELED:
        return 'textDisabled'
      case Result.HOUSE:
        return 'textDisabled'
      default:
        return 'text'
    }
  }

  const getRoundPrefix = (result) => {
    if (result === Result.LOSE) {
      return '-'
    }

    if (result === Result.WIN) {
      return '+'
    }

    return ''
  }

  const roundResult = getRoundResult(bet, currentEpoch)
  const resultTextColor = getRoundColor(roundResult)
  const resultTextPrefix = getRoundPrefix(roundResult)
  const isOpenRound = round.epoch === currentEpoch
  const isLiveRound = status === PredictionStatus.LIVE && round.epoch === currentEpoch - 1

  // Winners get the payout, otherwise the claim what they put it if it was canceled
  const payout = roundResult === Result.WIN ? getNetPayout(bet, REWARD_RATE) : amount

  const renderBetLabel = () => {
    if (isOpenRound) {
      return (
        <Flex alignItems="center">
          <WaitIcon color="primary" mr="6px" width="24px" />
          <Text color="primary" bold>
            {t('Starting Soon')}
          </Text>
        </Flex>
      )
    }

    if (isLiveRound) {
      return (
        <Flex alignItems="center">
          <PlayCircleOutlineIcon color="secondary" mr="6px" width="24px" />
          <Text color="secondary" bold>
            {t('Live Now')}
          </Text>
        </Flex>
      )
    }

    return (
      <>
        <Text fontSize="12px" color="#ffffff">
          {t('Your Result')}
        </Text>

        <Text
          style={{
            fontWeight: 700,
            marginTop: 3,
            fontSize: 16,
          }}
          color={resultTextColor}
          lineHeight={1}
        >
          {roundResult === Result.CANCELED ? (
            t('Cancelled')
          ) : roundResult === Result.HOUSE ? (
            <>
              {tooltipVisible && tooltip}
              <Flex alignItems="center" ref={targetRef}>
                <span style={{ color: '#ffffff' }}>{t('To Treasury')}</span>
                <InfoIcon width="16px" ml="4px" color="white" />
              </Flex>
            </>
          ) : (
            `${resultTextPrefix}${formatBnb(payout, displayedDecimals)}`
          )}
        </Text>
      </>
    )
  }

  const handleSuccess = async () => {
    // We have to mark the bet as claimed immediately because it does not update fast enough
    dispatch(markAsCollected({ [bet.round.epoch]: true }))
    dispatch(fetchLedgerData({ account, epochs: [bet.round.epoch] }))
  }

  return (
    <>
      <StyledBet onClick={toggleOpen} role="button">
        <Box width="48px">
          <Text textAlign="center">
            <Text fontSize="12px" color="#ffffff">
              {t('Round')}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                marginTop: 3,
                fontSize: '16px',
              }}
              color="#ffffff"
              lineHeight={1}
            >
              {round.epoch.toLocaleString()}
            </Text>
          </Text>
        </Box>
        <YourResult px="24px">{renderBetLabel()}</YourResult>
        {roundResult === Result.WIN && canClaim && (
          <CollectWinningsButton
            hasClaimed={!canClaim}
            onSuccess={handleSuccess}
            scale="sm"
            mr="8px"
            price={price || 0}
          >
            {t('Collect')}
          </CollectWinningsButton>
        )}
        {roundResult === Result.CANCELED && canClaim && (
          <ReclaimPositionButton epoch={bet.round.epoch} scale="sm" mr="8px">
            {t('Reclaim')}
          </ReclaimPositionButton>
        )}
        {!isOpenRound && !isLiveRound && (
          <CustomIconButton variant="text" scale="sm">
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </CustomIconButton>
        )}
      </StyledBet>
      {isOpen && <BetDetails bet={bet} result={getRoundResult(bet, currentEpoch)} />}
    </>
  )
}

export default HistoricalBet
