import { Card, CardBody, Flex, Spinner, WaitIcon, TooltipText, useTooltip, InfoIcon, CalculateOPXIcon, Box } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { NodeRound, BetPosition, NodeLedger } from 'state/types'
import useTheme from 'hooks/useTheme'
import { RoundResultBox } from '../RoundResult'
import MultiplierArrow from './MultiplierArrow'
import CardHeader, { getBorderBackground } from './CardHeader'
import styled, { keyframes } from 'styled-components'

interface CalculatingCardProps {
  round: NodeRound
  hasEnteredUp: boolean
  hasEnteredDown: boolean
  betAmount?: NodeLedger['amount']
}

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledCalculateOPXIcon = styled(CalculateOPXIcon)`
  animation: ${spinner} 3s linear infinite;
`


const CalculatingCard: React.FC<React.PropsWithChildren<CalculatingCardProps>> = ({
  round,
  hasEnteredUp,
  hasEnteredDown,
  betAmount
}) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('This round’s closing transaction has been submitted to the blockchain, and is awaiting confirmation.'),
    { placement: 'bottom' },
  )

  return (
    <>
      <Card borderBackground={getBorderBackground(theme, 'calculating')}>
        <CardHeader
          status="calculating"
          icon={<WaitIcon mr="4px" width="21px" />}
          title={t('Calculating')}
          epoch={round.epoch}
        />
        <CardBody p="16px">
          <MultiplierArrow isDisabled hasEntered={hasEnteredUp} betAmount={betAmount}/>
          <RoundResultBox>
            <Flex alignItems="center" justifyContent="center" height="100%">
              {/* <Spinner size={96} /> */}
              <Flex mt="8px" ref={targetRef} flexDirection="column">
                <StyledCalculateOPXIcon width="64px" mb={16}/>
                <Flex>
                  <TooltipText>{t('Calculating')}</TooltipText>
                  <InfoIcon ml="4px" />
                </Flex>
              </Flex> 
            </Flex>
          </RoundResultBox>
          <MultiplierArrow betPosition={BetPosition.BEAR} isDisabled hasEntered={hasEnteredDown} betAmount={betAmount}/>
        </CardBody>
      </Card>
      {tooltipVisible && tooltip}
    </>
  )
}

export default CalculatingCard
