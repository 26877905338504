import styled from 'styled-components'
import { Card, Box, BlockIcon, CardBody, OPXLogo, Flex, LogoOpx, Button, useModal } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { NodeRound, BetPosition, NodeLedger } from 'state/types'
import {
  useCollectWinningModalProps,
  useGetBufferSeconds,
  useGetCurrentEpoch,
  useGetHistory,
  useGetHistoryFilter,
  useGetIsClaimable,
} from 'state/predictions/hooks'
import { getFilteredBets, getHasRoundFailed, Result, getRoundResult } from 'state/predictions/helpers'
import useTheme from 'hooks/useTheme'
import { getRoundPosition } from '../../helpers'
import { RoundResult } from '../RoundResult'
import MultiplierArrow from './MultiplierArrow'
import CardHeader, { getBorderBackground } from './CardHeader'
import CollectWinningsOverlay from './CollectWinningsOverlay'
import CanceledRoundCard from './CanceledRoundCard'
import CalculatingCard from './CalculatingCard'
import ClaimPrizesModal from 'views/Lottery/components/ClaimPrizesModal'
import { useEffect } from 'react'
import useGetUnclaimedRewards from 'views/Lottery/hooks/useGetUnclaimedRewards'
import usePollOraclePrice from 'views/Predictions/hooks/usePollOraclePrice'
import CollectRoundWinningsModal from '../CollectRoundWinningsModal'
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch'
import { useConfig } from 'views/Predictions/context/ConfigProvider'
import CollectWinningsButton from '../CollectWinningsButton'
import useTokenInfo from 'hooks/useTokenInfo'
import useSWR from 'swr'
import { arrayURLFetcher } from 'utils/legacy'

interface ExpiredRoundCardProps {
  round: NodeRound
  betAmount?: NodeLedger['amount']
  hasEnteredUp: boolean
  hasEnteredDown: boolean
  hasClaimedUp: boolean
  hasClaimedDown: boolean
  bullMultiplier: string
  bearMultiplier: string
  isActive?: boolean
}

const StyledExpiredRoundCard = styled(Card)`
  opacity: 0.7;
  transition: opacity 300ms;

  &:hover {
    opacity: 1;
  }
  .card-enter-postition-down {
    top: 268px !important;
  }
`

const CollectWinning = styled(Flex)`
  padding: 22px 24px;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  gap: 24px;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.secondary};

  > button {
    flex: 1;
    height: 36px;
    color: black;
  }
`

const CollectWinningButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
`

const ExpiredRoundCard: React.FC<React.PropsWithChildren<ExpiredRoundCardProps>> = ({
  round,
  betAmount,
  hasEnteredUp,
  hasEnteredDown,
  hasClaimedUp,
  hasClaimedDown,
  bullMultiplier,
  bearMultiplier,
  isActive,
}) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { epoch, lockPrice, closePrice } = round
  const betPosition = getRoundPosition(lockPrice, closePrice)
  const bufferSeconds = useGetBufferSeconds()
  const { price } = usePollOraclePrice()
  // const tokenInfo = useTokenInfo();
  // const { data: tokenInfo } = useSWR('https://api.opx.finance/opx/token-info?token=OPX&chainCode=Optimism', {
  //   fetcher: arrayURLFetcher,
  // })
  const { history, isLoadingHistory } = useCollectWinningModalProps()
  const hasRoundFailed = getHasRoundFailed(round.oracleCalled, round.closeTimestamp, bufferSeconds)

  const isClaimable = useGetIsClaimable(epoch)

  if (hasRoundFailed) {
    return <CanceledRoundCard round={round} />
  }

  if (!closePrice) {
    return (
      <CalculatingCard
        round={round}
        hasEnteredDown={hasEnteredDown}
        hasEnteredUp={hasEnteredUp}
        betAmount={betAmount}
      />
    )
  }

  const cardProps = isActive
    ? {
        isActive,
      }
    : {
        borderBackground: getBorderBackground(theme, 'expired'),
      }

  // useEffect(() => {
  //   onPresentClaimModal();
  // })
  return (
    <Box position="relative">
      <StyledExpiredRoundCard {...cardProps}>
        <CardHeader
          status="expired"
          icon={<BlockIcon mr="4px" width="21px" color="textDisabled" />}
          title={t('Expired')}
          epoch={round.epoch}
        />
        <CardBody p="16px" style={{ position: 'relative' }}>
          {isClaimable && (
            <CollectWinning
              justifyContent="space-between"
              alignItems="center"
              position="absolute"
              className={`${hasEnteredDown ? 'card-enter-postition-down' : ''}`}
            >
              <LogoOpx width={40} height={40} />
              <CollectWinningsButton
                hasClaimed={false}
                variant="success"
                px="0"
                price={price || 0}
                style={{ background: '#95DE64', borderRadius: '8px', fontWeight: 500, fontSize: '14px' }}
              >
                {t('Collect Winnings')}
              </CollectWinningsButton>
              {/* <CollectWinningButton variant="success" px="0" price={price}>
                Collect Winnings
              </CollectWinningButton> */}
            </CollectWinning>
          )}
          <MultiplierArrow
            isExpired
            betAmount={betAmount}
            multiplier={bullMultiplier}
            isActive={betPosition === BetPosition.BULL}
            hasEntered={hasEnteredUp}
            hasClaimed={hasClaimedUp}
            isHouse={betPosition === BetPosition.HOUSE}
          />
          <RoundResult round={round} hasFailed={hasRoundFailed} />
          <MultiplierArrow
            betAmount={betAmount}
            multiplier={bearMultiplier}
            betPosition={BetPosition.BEAR}
            isActive={betPosition === BetPosition.BEAR}
            hasEntered={hasEnteredDown}
            hasClaimed={hasClaimedDown}
            isHouse={betPosition === BetPosition.HOUSE}
          />
        </CardBody>
      </StyledExpiredRoundCard>
      {/* <CollectWinningsOverlay epoch={epoch} isBottom={hasEnteredDown} /> */}
    </Box>
  )
}

export default ExpiredRoundCard
