import React from 'react';
import styled from 'styled-components'
import { Collapse } from 'antd'
import { Text, Button, Box, Flex, ArrowDropDownFooter, WinningCupIcon, Heading, ArrowDownIcon, useTooltip, SuggestIcon, LoseIcon, ArrowUpIcon } from '@pancakeswap/uikit';

import { Bet, HistoryFilter } from 'state/types'
import HistoricalBet from '../HistoricalBet'
import V1ClaimCheck from '../../v1/V1ClaimCheck'
import { useTranslation } from '@pancakeswap/localization';
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch';
import { useWeb3React } from '../../../../../../packages/wagmi/src/useWeb3React';
import { useGetCurrentHistoryPage, useGetHasHistoryLoaded, useGetHistoryFilter, useGetIsFetchingHistory } from 'state/predictions/hooks';
import { useConfig } from 'views/Predictions/context/ConfigProvider';
import { fetchNodeHistory } from 'state/predictions';
import { orderBy } from 'lodash';


const { Panel } = Collapse;

const GreenText = styled(Text)`
  color: ${({theme}) => theme.colors.success};
  font-weight: 700;
`

const RedText = styled(Text)`
  color: ${({theme}) => theme.colors.failure};
  font-weight: 700;
`

const CollectButton = styled(Button)`
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  margin-left: auto;
  border-radius: ${({rounded}) => rounded || '8px'};;
  color: ${({textColor}) => textColor || 'white'};
  width: ${({width}) => width || 'unset'};
  max-height:${({maxHeight}) => maxHeight || 'unset'};
`

const StyledHistoryBox = styled(Box)`
  border: 1px solid ${({theme}) => theme.colors.white10};
  border-radius: 24px;
`

const StyledHistory = styled.div`
  .ant-collapse {
    border: none !important;

    .ant-collapse-item {
      border: none;

      .ant-collapse-header {
        align-items: center !important;
      }

      &+ .ant-collapse-item {
        border-top: 1px solid ${({theme}) => theme.colors.white10};
      }

      .ant-collapse-content {
        border: none;

        .ant-collapse-content-box {
          background: ${({theme}) => theme.colors.backgroundAlt};
          padding: 24px;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-expand-icon svg{
        transform: rotate(180deg);
      }
    }
  }

`

interface RoundsTabProps {
  hasBetHistory: boolean
  bets: Bet[]
}


const AllFilter: React.FC<React.PropsWithChildren<RoundsTabProps>> = ({ hasBetHistory, bets }) => {
  const { t } = useTranslation()
  const dispatch = useLocalDispatch()
  const { account } = useWeb3React()
  const hasHistoryLoaded = useGetHasHistoryLoaded()
  const currentHistoryPage = useGetCurrentHistoryPage()
  const isFetchingHistory = useGetIsFetchingHistory()
  const { token } = useConfig()
  const { targetRef, tooltip, tooltipVisible } = useTooltip(t('Includes your original position and your winnings, minus the 3% fee'), {
    placement: 'bottom',
  })

  const handleClick = () => {
    dispatch(fetchNodeHistory({ account, page: currentHistoryPage + 1 }))
  }

  const v1Claim = token.symbol === 'BNB' ? <V1ClaimCheck /> : null

  return <StyledHistory>
            {v1Claim}
            {orderBy(bets, ['round.epoch'], ['desc']).map((bet) => (
              <HistoricalBet key={bet.round.epoch} bet={bet} />
            ))}
            {hasBetHistory && !hasHistoryLoaded && (
              <Flex alignItems="center" justifyContent="center" py="24px">
                <Button variant="secondary" scale="sm" onClick={handleClick} disabled={isFetchingHistory}>
                  {t('View More')}
                </Button>
              </Flex>
            )}
          </StyledHistory>
}

export default AllFilter;