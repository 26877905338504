import { memo } from 'react'
import styled from 'styled-components'
import { Box, Flex, PowerByChainLinkIcon } from '@pancakeswap/uikit'
import { useGetPredictionsStatus, useIsChartPaneOpen, useIsHistoryPaneOpen } from 'state/predictions/hooks'
import { PredictionStatus } from 'state/types'
import MobileMenu from './components/MobileMenu'
import History from './History'
import Positions from './Positions'
import MobileChart from './MobileChart'
import { ErrorNotification, PauseNotification } from './components/Notification'
import { PageView } from './types'
import Menu from './components/Menu'
import LoadingSection from './components/LoadingSection'

const StyledMobile = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  max-height: 100%;
  /* padding-bottom: 60px; */

  ${({ theme }) => theme.mediaQueries.xl} {
    display: none;
  }

  .wrapper-content {
    margin-top: 0px;
  }

  @media screen and (max-width: 991px){
    justify-content: flex-start;
    .wrapper-content {
      /* margin-top: 80px; */
    }
    .flex-container {
      justify-content: flex-start;
      .wrapper-content {
        /* margin-top: 80px; */
      } 
    }
    .styled-mobile-box {
      height: 85vh;
      margin-top: 80px;
    }
  }

`

const PowerLinkStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
`

const getView = (isHistoryPaneOpen: boolean, isChartPaneOpen: boolean): PageView => {
  if (isHistoryPaneOpen) {
    return PageView.HISTORY
  }

  if (isChartPaneOpen) {
    return PageView.CHART
  }

  return PageView.POSITIONS
}

const Mobile: React.FC<React.PropsWithChildren> = () => {
  const isHistoryPaneOpen = useIsHistoryPaneOpen()
  const isChartPaneOpen = useIsChartPaneOpen()
  const view = getView(isHistoryPaneOpen, isChartPaneOpen)
  const status = useGetPredictionsStatus()

  return (
    <StyledMobile>
      <Box className='styled-mobile-box'>
        {view === PageView.POSITIONS && (
          <Flex justifyContent="center" alignItems="center" flexDirection="column" minHeight="100%" className='flex-container'>
            {status === PredictionStatus.ERROR && <ErrorNotification />}
            {status === PredictionStatus.PAUSED && <PauseNotification />}
            {[PredictionStatus.INITIAL, PredictionStatus.LIVE].includes(status) && (
              <Box className="wrapper-content" width="100%">
                <Menu />
                {status === PredictionStatus.LIVE ? <Positions view={view} /> : <LoadingSection />}
                {/* <PowerLinkStyle>
                  <img
                    src="/images/powered-by-chainlink.png"
                    alt="Powered by ChainLink"
                    style={{ width: '170px', maxHeight: '100%' }}
                  />
                </PowerLinkStyle> */}
                <PowerLinkStyle>
                  <PowerByChainLinkIcon/>
                </PowerLinkStyle>
              </Box>
            )}
          </Flex>
        )}
        {view === PageView.CHART && <MobileChart />}
        {view === PageView.HISTORY && <History />}
      </Box>
      {status === PredictionStatus.PAUSED ? null : <MobileMenu />}
    </StyledMobile>
  )
}

export default memo(Mobile)
