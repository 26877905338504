import { useWeb3React } from '@pancakeswap/wagmi'
import {
  ArrowForwardIcon,
  Box,
  Button,
  Radio,
  Flex,
  Heading,
  Text,
  ButtonMenu,
  ButtonMenuItem,
} from '@pancakeswap/uikit'
import useLocalDispatch from 'contexts/LocalRedux/useLocalDispatch'
import { HistoryFilter } from 'state/types'
import { setHistoryFilter, setHistoryPaneState } from 'state/predictions'
import { useGetHistoryFilter, useGetIsFetchingHistory } from 'state/predictions/hooks'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'

const Filter = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin-right: 16px;

  .radio-history {
    width: 16px;
    height: 16px;

    background-color: #DBD6D6;

    &:checked {
      /* width: 7px;
      height: 7px;

      top: 5.2px;
      left: 4.5px;
*/
      background-color: #ffffff; 
      border: 4px solid #FF4D4F;
    }
  }
`

const StyledHeader = styled(Box)`
  flex: none;
  padding: 50px 16px 8px 16px;
`

const ButtonMenuContainer = styled.div`
  /* margin-bottom: 16px; */
  width: 100%;

  & > div {
    width: 100%;
  }

  & button {
    width: 100%;
    border-radius: 999px;
  }
`

const CloseHistoryButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: 700;
`

interface HeaderProps {
  activeTab: HistoryTabs
  setActiveTab: (value: HistoryTabs) => void
}

export enum HistoryTabs {
  ROUNDS,
  PNL,
}

const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({ activeTab, setActiveTab }) => {
  const historyFilter = useGetHistoryFilter()
  const isFetchingHistory = useGetIsFetchingHistory()
  const { t } = useTranslation()
  const dispatch = useLocalDispatch()
  const { account } = useWeb3React()

  const handleClick = () => {
    dispatch(setHistoryPaneState(false))
  }

  const handleChange = (newFilter: HistoryFilter) => async () => {
    if (newFilter !== historyFilter) {
      dispatch(setHistoryFilter(newFilter))
    }
  }

  const switchTab = async (tabIndex: number) => {
    setActiveTab(tabIndex)
    await handleChange(HistoryFilter.ALL)()
  }

  return (
    <StyledHeader>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="18px" fontWeight="700" lineHeight="27px">
          {t('History')}
        </Text>
        <CloseHistoryButton onClick={handleClick} variant="text" endIcon={<ArrowForwardIcon color="white" />} px="0">
          {t('Close')}
        </CloseHistoryButton>
      </Flex>
      <ButtonMenuContainer>
        <ButtonMenu activeIndex={activeTab} scale="sm" variant="danger" onItemClick={switchTab}>
          <ButtonMenuItem>{t('Rounds')}</ButtonMenuItem>
          <ButtonMenuItem>{t('PNL')}</ButtonMenuItem>
        </ButtonMenu>
      </ButtonMenuContainer>
      {activeTab === HistoryTabs.ROUNDS && (
        <>
          <Text color="#ffffff" fontSize="12px" mt="16px" mb="10px">
            {t('Filter')}
          </Text>
          <Flex alignItems="center">
            <Filter>
              <Radio
                className="radio-history"
                scale="sm"
                checked={historyFilter === HistoryFilter.ALL}
                disabled={isFetchingHistory || !account}
                onChange={handleChange(HistoryFilter.ALL)}
              />
              <Text ml="4px">{t('All')}</Text>
            </Filter>
            <Filter>
              <Radio
                className="radio-history"
                scale="sm"
                checked={historyFilter === HistoryFilter.COLLECTED}
                disabled={isFetchingHistory || !account}
                onChange={handleChange(HistoryFilter.COLLECTED)}
              />
              <Text ml="4px">{t('Collected')}</Text>
            </Filter>
            <Filter>
              <Radio
                className="radio-history"
                scale="sm"
                checked={historyFilter === HistoryFilter.UNCOLLECTED}
                disabled={isFetchingHistory || !account}
                onChange={handleChange(HistoryFilter.UNCOLLECTED)}
              />
              <Text ml="4px">{t('Uncollected')}</Text>
            </Filter>
          </Flex>
        </>
      )}
    </StyledHeader>
  )
}

export default Header
