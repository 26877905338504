import { BoxProps, Flex, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { BetPosition, Round } from 'state/types'
import { useTranslation } from '@pancakeswap/localization'
import { formatUsd } from '../History/helpers'
import PositionTag from '../PositionTag'
import { LockPriceHistoryRow, PrizePoolHistoryRow, RoundResultBox } from './styles'
import { useConfig } from '../../context/ConfigProvider'

interface RoundResultProps extends BoxProps {
  round: Round
}

const StyledRoundHistoryBox = styled(RoundResultBox)`
  padding: 1px;

  > div {
    padding: 24px;
  }
`

const RoundResult: React.FC<React.PropsWithChildren<RoundResultProps>> = ({ round, children, ...props }) => {
  const { displayedDecimals } = useConfig()
  const { lockPrice, closePrice, totalAmount } = round
  // console.log('totalAmount', totalAmount)
  const betPosition = closePrice > lockPrice ? BetPosition.BULL : BetPosition.BEAR
  const isPositionUp = betPosition === BetPosition.BULL
  const { t } = useTranslation()
  const priceDifference = closePrice - lockPrice

  return (
    <StyledRoundHistoryBox betPosition={betPosition} {...props} style={{height: 'auto'}}>
      <Text color="textSubtle" fontSize="14px" bold textTransform="uppercase" mb="8px" style={{color: 'rgba(255, 255, 255, 0.6)'}}>
        {t('Closed Price')}
      </Text>
      {round.failed ? (
        <Text bold textTransform="uppercase" color="textDisabled" mb="16px" fontSize="24px" style={{fontWeight: 700}}>
          {t('Cancelled')}
        </Text>
      ) : (
        <Flex alignItems="center" justifyContent="space-between" mb="16px">
          <Text color={isPositionUp ? 'success' : 'failure'} bold fontSize="24px">
            {formatUsd(closePrice, displayedDecimals)}
          </Text>
          <PositionTag style={{height: '37px'}} betPosition={betPosition}>{formatUsd(priceDifference, displayedDecimals)}</PositionTag>
        </Flex>
      )}
      {lockPrice !== 0 && <LockPriceHistoryRow lockPrice={lockPrice} style={{marginBottom: '16px'}}/>}
      <PrizePoolHistoryRow totalAmount={totalAmount} style={{marginBottom: '16px'}}/>
      {children}
    </StyledRoundHistoryBox>
  )
}

export default RoundResult
