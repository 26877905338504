import { FC } from 'react'
import {
  ModalContainer,
  ModalBody,
  Text,
  Button,
  InjectedModalProps,
  Flex,
  Image,
  Heading,
  Checkbox,
  Box,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import { useUserPredictionChainlinkChartDisclaimerShow } from 'state/user/hooks'

const Ul = styled.ul`
  color: ${({ theme }) => theme.colors.textSubtle};
  list-style-position: outside;
  margin-left: 16px;

  & > li {
    font-size: 14px;
    line-height: 1.5;
  }
`

const ChartDisclaimer: FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss }) => {
  const [showDisclaimer, setShowDisclaimer] = useUserPredictionChainlinkChartDisclaimerShow()
  const { t } = useTranslation()

  const handleConfirm = () => {
    onDismiss()
  }

  return (
    <ModalContainer className="oracle-chart-modal" $minWidth="320px">
      <ModalBody p="24px" maxWidth="400px">
        <Flex justifyContent="center" mb="32px">
          <Image src="/images/predictions/chartwarning21.svg" width={190} height={118} />
        </Flex>
        <Heading
          style={{
            fontSize: 18,
          }}
          as="h3"
          size="sm"
        >
          {t('Currently showing charts from Chainlink oracle')}
        </Heading>
        <Text color="textSubtle" fontSize="12px" my="24px" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          {t('The price you see comes directly from the Chainlink oracle, which powers the Prediction game.')}
        </Text>
        <Ul style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          <li
            style={{
              fontSize: 12,
            }}
          >
            {t('Oracle price refreshes every ~20 seconds.')}
          </li>
          <li
            style={{
              fontSize: 12,
            }}
          >
            {t('In comparison with the TradingView chart, this chart refreshes slower and with fewer features.')}
          </li>
        </Ul>
        <Box>
          <Button
            width="100%"
            variant="primary"
            onClick={handleConfirm}
            my="16px"
            style={{ background: '#95DE64', color: '#060303', fontSize: 14 }}
          >
            {t('I understand')}
          </Button>
        </Box>
        <label htmlFor="checkbox" style={{ display: 'block', cursor: 'pointer', marginBottom: '24px' }}>
          <Flex alignItems="center">
            <div style={{ flex: 'none' }}>
              <Checkbox
                id="checkbox"
                scale="sm"
                checked={!showDisclaimer}
                onChange={() => setShowDisclaimer(!showDisclaimer)}
              />
            </div>
            <Text fontSize={12} ml="8px">
              {t("Don't show this again")}
            </Text>
          </Flex>
        </label>
      </ModalBody>
    </ModalContainer>
  )
}

export default ChartDisclaimer
