import styled from 'styled-components'
import { Card, CardBody, Heading, ArrowBackIcon, IconButton } from '@pancakeswap/uikit'
import { useRouter } from 'next/router'
import { useTranslation } from '@pancakeswap/localization'
import { PredictionSupportedSymbol } from 'state/types'
import { useConfig } from 'views/Predictions/context/ConfigProvider'

interface NotificationProps {
  title: string
  className?: string
}

const Wrapper = styled.div`
  width: 100%;
  position: absolute !important;
  top: 50%;
  transform: translateY(-70%);
  z-index: 999;
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;


  .title-pause {
    cursor: pointer;

    display: flex;
    align-items: center;

    font-weight: 700;
    font-size: 18px;
    line-height: 150%;

    color: #ffffff;

    svg {
      margin-right: 8px;
    }
  }

  .content-pause {
    background: #232121;
    overflow: unset;
    height: unset;

    button {
      background: #ff4d4f;

      font-weight: 500;
      font-size: 14px;
      line-height: 150%;

      color: #ffffff;
    }
  }

  &.noti-pause {
    position: relative;

    h2 {
      display: none;
    }
  }

  .img-pause {
    position: absolute;

    top: -45px;
    right: 0;
  }

  .card-wrapper {
    width: 360px;
  }
`

const CardWrapper = styled.div`
  width: 320px;
`

const BunnyDecoration = styled.div`
  position: relative;
  top: 12px;
  text-align: center;
  width: 100%;
  z-index: 5;
  cursor: pointer;
`

const BackButtonStyle = styled(IconButton)`
  position: relative;
  top: 120px;
  width: 40%;
`

const BackButton = () => {
  const { t } = useTranslation()

  return (
    <BackButtonStyle variant="primary" width="100%">
      <ArrowBackIcon color="white" mr="8px" />
      {t('Back')}
    </BackButtonStyle>
  )
}

const Notification: React.FC<React.PropsWithChildren<NotificationProps>> = ({ title, children, className }) => {
  return (
    <Wrapper className={className}>
      <CardWrapper className="card-wrapper">
        <Card className="content-pause">
          <CardBody>
            <Heading mb="24px">{title}</Heading>
            {children}
          </CardBody>
        </Card>
      </CardWrapper>
    </Wrapper>
  )
}

export default Notification
